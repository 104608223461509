@import './gotham-font.css';
/* TODO to investigate how to use lazy load this stylesheet */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary-50: #f2d0ce;
    --primary-100: #ebb8b6;
    --primary-200: #e5a09d;
    --primary-300: #d7716c;
    --primary-400: #d15954;
    --primary-500: #ca413b;
    --primary-600: #bd120a;
    --primary-700: #970e08;
    --primary-800: #710b06;
    --primary-900: #4c0704;
    --primary: var(--primary-600);

    --secondary: #555961;

    --sorairo-blue-50: #e5f5ff;
    --sorairo-blue-200: #9ecff0;
    --sorairo-blue-600: #4c9bcf;
    --sorairo-blue: var(--sorairo-blue-600);

    --asagi-blue-800: #1b8291;

    --akane-red-800: #b82400;

    --fuji-purple-800: #733095;

    --light-grey-50: #fdfefe;
    --light-grey-100: #fbfcfd;
    --light-grey-200: #fafbfc;
    --light-grey-300: #f6f9fa;
    --light-grey-400: #f4f7f8;
    --light-grey-500: #f1f5f6;
    --light-grey-600: #edf2f4;
    --light-grey-700: #d5dadc;
    --light-grey-800: #bec2c3;
    --light-grey-900: #a6a9ab;
    --light-grey: var(--light-grey-600);

    --medium-grey-50: #f6f6f7;
    --medium-grey-100: #ecedee;
    --medium-grey-200: #e3e4e6;
    --medium-grey-300: #d9dbde;
    --medium-grey-400: #c7c8cd;
    --medium-grey-500: #b4b6bd;
    --medium-grey-600: #a1a4ac;
    --medium-grey-700: #81838a;
    --medium-grey-800: #616267;
    --medium-grey-900: #404245;
    --medium-grey: var(--medium-grey-600);

    --dark-grey-50: #aeafb2;
    --dark-grey-100: #9a9b9f;
    --dark-grey-200: #85878c;
    --dark-grey-300: #717379;
    --dark-grey-400: #5d5f65;
    --dark-grey-500: #484b52;
    --dark-grey-600: #34373f;
    --dark-grey-700: #2f3239;
    --dark-grey-800: #24272c;
    --dark-grey-900: #151619;
    --dark-grey: var(--dark-grey-600);

    --dark-red-50: #f2d0ce;
    --dark-red-300: #d7716c;
    --dark-red-600: #bd120a;
    --dark-red: #e1242a;

    --info-50: #eff6ff;
    --info-100: #cbe1f2;
    --info-300: #a8cde8;
    --info-600: #2563eb;
    --info-800: #367299;
    --info: var(--info-600);

    --warning-50: #fffbeb;
    --warning-300: #fcd34d;
    --warning-600: #d97706;
    --warning: var(--warning-600);

    --success-50: #f0fdf4;
    --success-600: #16a34a;
    --success: var(--success-600);

    --tertiary: #73757a;

    --colon: ':'; /* a space symbol cannot be the part of this CSS var, otherwise double-click selection works wrong */
  }

  @layer utilities {
    .with-colon:after {
      content: var(--colon);
      padding-right: 5px;
    }
  }

  html {
    @apply font-gotham;
  }

  table th,
  table td {
    @apply p-2;
  }

  svg use {
    fill: currentColor;
  }

  :focus-visible {
    outline: 2px solid var(--primary);
    outline-offset: 2px;
    border-radius: 2px;
  }
}
